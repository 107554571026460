const Component = (props) => {
  if (props.ember) {
    return <div className='grow bg-chTan-200 md:ml-[300px]'>
      <div className='md:p-8 mt-4 mx-4 md:mt-0 md:max-w-[960px] md:mx-auto'>
        {props.children}
      </div>
    </div>
  }
  
  // height calc is nav (100) plus footer plus footer height (50) top margin (75)
  return <div className='relative w-full px-2 pt-8 md:pt-12 md:px-0 max-w-[970px] mx-auto min-h-[calc(100vh-200px)] md:min-h-[calc(100vh-225px)]'>
    {props.children}
  </div>
}

export default Component
